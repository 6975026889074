import './Sidebar.scss';

import {Avatar, Tooltip} from '@mui/material';
import {
  BACKUPS_PATH,
  BRANCHES_PATH,
  CLIENTS_PATH,
  COMPANIES_PATH,
  COMPANY_OWNERS_PATH,
  DATA_COLLECTOR_PATH,
  DEALERS_PAGE_PATH,
  LOGS_PATH,
  MANIFEST_PATH,
  REPORTS_PATH,
  STATIONS_PATH,
} from 'router/constants';
import {
  BackupIcon,
  BranchIcon,
  CompaniesIcon,
  CompanyOwnerIcon,
  DatacollectorIcon,
  DealersIcon,
  LogIcon,
  ManifestIcon,
  ReportIcon,
  SidebarCollapseDoubleLeft,
  SidebarCollapseDoubleRight,
  StationIcon,
  UsersIcon,
} from 'assets/icons';
import React, {FC, useState} from 'react';
import store, {useAppDispatch, useAppSelector} from 'store/store';

import Button from '@mui/material/Button';
import CookieIcon from 'assets/icons/Cokkie.svg';
import History from 'core/history';
import {LOGIN_PAGE_PATH} from 'router/constants';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {NavLink} from 'react-router-dom';
import User from 'assets/icons/User.svg';
import {UserRole} from 'enums';
import adminHeaderAvatar from 'assets/icons/adminHeaderAvatar.svg';
import adminHeaderLogo from 'assets/icons/adminHeaderLogo.svg';
import {api} from '../../../store/slices/api';
import {avatarMenuItemStyle} from 'styles/MUIStyles/common';
import chevronDown from 'assets/icons/chevronDown.svg';
import chevronUp from 'assets/icons/chevronUp.svg';
import {clearCacheAndReload} from 'core/utils';
import {setIsAuth} from 'store/slices/authSlice';
import signOut from 'assets/icons/signOut.svg';
import {useLocalStorage} from 'hooks';
import {useNavigate} from 'react-router-dom';

type MenuItemType = {
  path: string;
  icon: React.ReactNode;
  name: string;
};

const roleBasedMenuItems: Record<string, MenuItemType[]> = {
  admin: [
    {path: DEALERS_PAGE_PATH, icon: <DealersIcon />, name: 'Dealers'},
    {
      path: COMPANY_OWNERS_PATH,
      icon: <CompanyOwnerIcon />,
      name: 'Company Owners',
    },
    {path: CLIENTS_PATH, icon: <UsersIcon />, name: 'Users'},
    {path: COMPANIES_PATH, icon: <CompaniesIcon />, name: 'Companies'},
    {path: BRANCHES_PATH, icon: <BranchIcon />, name: 'Branches'},
    {path: STATIONS_PATH, icon: <StationIcon />, name: 'Stations'},
    {path: BACKUPS_PATH, icon: <BackupIcon />, name: 'Backups'},
    {path: REPORTS_PATH, icon: <ReportIcon />, name: 'Reports'},
    {path: MANIFEST_PATH, icon: <ManifestIcon />, name: 'Manifests'},
    {path: LOGS_PATH, icon: <LogIcon />, name: 'Logs & Alerts'},
    {
      path: DATA_COLLECTOR_PATH,
      icon: <DatacollectorIcon />,
      name: 'DC versions',
    },
  ],
  dealer: [
    {
      path: COMPANY_OWNERS_PATH,
      icon: <CompanyOwnerIcon />,
      name: 'Company Owners',
    },
    {path: CLIENTS_PATH, icon: <UsersIcon />, name: 'Users'},
    {path: COMPANIES_PATH, icon: <CompaniesIcon />, name: 'Companies'},
    {path: BRANCHES_PATH, icon: <BranchIcon />, name: 'Branches'},
    {path: STATIONS_PATH, icon: <StationIcon />, name: 'Stations'},
    {path: BACKUPS_PATH, icon: <BackupIcon />, name: 'Backups'},
    {path: REPORTS_PATH, icon: <ReportIcon />, name: 'Reports'},
    {path: MANIFEST_PATH, icon: <ManifestIcon />, name: 'Manifests'},
    {path: LOGS_PATH, icon: <LogIcon />, name: 'Logs & Alerts'},
  ],
  owner: [
    {path: CLIENTS_PATH, icon: <UsersIcon />, name: 'Users'},
    {path: BRANCHES_PATH, icon: <BranchIcon />, name: 'Branches'},
    {path: STATIONS_PATH, icon: <StationIcon />, name: 'Stations'},
    {path: BACKUPS_PATH, icon: <BackupIcon />, name: 'Backups'},
    {path: REPORTS_PATH, icon: <ReportIcon />, name: 'Reports'},
  ],
  client: [
    {path: BACKUPS_PATH, icon: <BackupIcon />, name: 'Backups'},
    {path: REPORTS_PATH, icon: <ReportIcon />, name: 'Reports'},
  ],
};

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.auth.role);

  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
    'isSidebarOpen',
    false,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState: boolean) => !prevState);
  };
  const handleSignOut = async () => {
    await clearCacheAndReload();
    store.dispatch(setIsAuth(false));
    localStorage.clear();
    dispatch(api.util.resetApiState());
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleProfileClick = () => {
    navigate('./profile');
    handleClose();
  };
  const clearCache = async () => {
    await clearCacheAndReload();
    window.location.href = window.location.origin + `?timestamp=${Date.now()}`;
  };
  const sidebarMenuItems = userRole
    ? roleBasedMenuItems[userRole.toLowerCase()]
    : roleBasedMenuItems.client;

  return (
    <aside
      className={isSidebarOpen ? 'sidebar active' : 'sidebar'}
      style={{
        width: isSidebarOpen ? '256px' : '60px',
        transition: 'width 0.2s linear',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <nav
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div className="sidebarBottom" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <>
              <img src={adminHeaderLogo} alt="logo" />
              <span>
                {userRole === UserRole.Admin ? 'Admin panel' : 'Backup service'}
              </span>
              <div className="arrows">
                <SidebarCollapseDoubleLeft />
              </div>
            </>
          ) : (
            <>
              <img src={adminHeaderLogo} alt="logo" />
              <div className="arrowsClosed">
                <SidebarCollapseDoubleRight />
              </div>
            </>
          )}
        </div>
        <ul className={'menuList'}>
          {sidebarMenuItems.map((menuItem) => (
            <li key={menuItem.path} className="menuListItem">
              <NavLink to={menuItem.path} className="menuListLink">
                {isSidebarOpen ? (
                  menuItem.icon
                ) : (
                  <Tooltip title={menuItem.name} arrow placement={'right'}>
                    <Button>{menuItem.icon}</Button>
                  </Tooltip>
                )}
                <span>{isSidebarOpen ? menuItem.name : ''}</span>
              </NavLink>
            </li>
          ))}
        </ul>

        <div className="avatarSideBarClosed">
          {isSidebarOpen ? (
            <div className="avatar">
              <div className="avatarMenu">
                <Avatar alt="Avatar" src={adminHeaderAvatar} />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <img src={isMenuOpen ? chevronUp : chevronDown} alt="menu" />
                </Button>
                <Menu
                  id="basic-menu2"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    onClick={handleProfileClick}
                    sx={avatarMenuItemStyle}
                  >
                    <img src={User} alt="user" />
                    <span>My account</span>
                  </MenuItem>
                  <MenuItem onClick={handleSignOut} sx={avatarMenuItemStyle}>
                    <img src={signOut} alt="user" />
                    <span>Sign out</span>
                  </MenuItem>
                </Menu>
              </div>
              {/* <div className="menuText">
                <p>Text for subscription 25$</p>
              </div> */}
            </div>
          ) : (
            <div className="avatarPopup">
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Avatar
                  alt="Avatar"
                  src={adminHeaderAvatar}
                  className="avatarClosed"
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={clearCache} sx={avatarMenuItemStyle}>
                  <img src={CookieIcon} alt="Cookie" />
                  <span>Clear Cache</span>
                </MenuItem>
                <MenuItem onClick={handleProfileClick} sx={avatarMenuItemStyle}>
                  <img src={User} alt="user" />
                  <span>My account</span>
                </MenuItem>
                <MenuItem onClick={handleSignOut} sx={avatarMenuItemStyle}>
                  <img src={signOut} alt="user" />
                  <span>Sign out</span>
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
