import {UserRole} from '../enums';
import axios from 'axios';
import {log} from 'console';

export const calculateItemNumbers = (
  firstItemOnPage: number,
  lastItemOnPage: number,
) => {
  const items: number[] = [];

  for (let i = firstItemOnPage; i <= lastItemOnPage; i++) {
    items.push(i);
  }

  return items;
};

export const downloadFile = async (
  url: string,
  setDownloadAllBackupsLoading?: (isLoading: boolean) => void,
) => {
  if (setDownloadAllBackupsLoading) {
    setDownloadAllBackupsLoading(true);
  }
  try {
    const response = await axios.get(url, {responseType: 'blob'});
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error(`Ошибка при скачивании файла из ${url}: ${error}`);
  } finally {
    if (setDownloadAllBackupsLoading) {
      setDownloadAllBackupsLoading(false);
    }
  }
};

export const downloadMultipleFiles = async (
  backupLinksArray: string[],
  setDownloadFileLoading: (loading: boolean) => void,
) => {
  console.log(backupLinksArray);
  setDownloadFileLoading(true);

  const promises = backupLinksArray?.map((backupLink) =>
    downloadFile(backupLink),
  );
  await Promise.allSettled(promises);
  setDownloadFileLoading(false);
};

export const downloadAllBackupFiles = async (
  backups: {
    fileLink: string;
  }[],
  setDownloadFileLoading: (loading: boolean) => void,
) => {
  setDownloadFileLoading(true);
  const promises = backups.map((backup) => downloadFile(backup.fileLink));
  await Promise.allSettled(promises);
  setDownloadFileLoading(false);
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const checkAllowedRoles = (
  allowedRoles: UserRole[],
  userRole: string | null,
) => {
  return allowedRoles.some((allowedRole) => allowedRole === userRole);
};

export async function clearCacheAndReload() {
  try {
    // Удаление всех зарегистрированных Service Workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
      console.log('Service Worker cache cleared.');
    }

    // Очистка кэша через Cache Storage API
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
      }
      console.log('Browser cache cleared.');
    }

    // Очистка localStorage, кроме версии
    const version = localStorage.getItem('version'); // Сохраняем версию
    const accessToken = localStorage.getItem('accessToken'); // Сохраняем версию
    const refreshToken = localStorage.getItem('refreshToken'); // Сохраняем версию
    localStorage.clear(); // Полностью очищаем localStorage
    if (version) {
      localStorage.setItem('version', version); // Восстанавливаем версию
    }
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken); // Восстанавливаем версию
    }
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken); // Восстанавливаем версию
    }
    console.log('LocalStorage cleared, version preserved.');

    // Полная очистка cookies
    // const cookies = document.cookie.split(';');
    // for (const cookie of cookies) {
    //   const [key] = cookie.split('=');
    //   const trimmedKey = key.trim();

    //   // Удаление cookie
    //   document.cookie = `${trimmedKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // }
    // console.log('All cookies cleared.');
    console.log('work');

    // Перезагрузка страницы с обходом кэша
  } catch (error) {
    console.error('Failed to clear cache:', error);
  }
}
